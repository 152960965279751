import React from 'react';
import {
  string, node, func, bool,
} from 'prop-types';
import { navigate } from 'gatsby';

import { REGISTRATION_START } from 'constants/navigation';

import {
  Card, Box, CardHeader, CardContent, Typography,
} from '@material-ui/core';
import useStyles from './styles';

const BaseSubscriptionSelect = ({
  avatar,
  title,
  subheader = '',
  description = '',
  name = '',
  onClick = () => {},
  active = false,
}) => {
  const styles = useStyles();

  return (
    <Box
      border={1}
      borderRadius={1}
      className={active ? styles.activeCardContainer : styles.cardContainer}
      >
    <Card>
      <CardHeader
        avatar={avatar}
        title={title}
        subheader={subheader}
        className={styles.pointer}
        onClick={async () => {
          const buildDataLayer = await import('data-layer');
          const dataLayer = buildDataLayer.default();
          dataLayer.trackLanding({
            descriptor: 'navigate_registration:registration',
            label: name,
          });
          navigate(`${REGISTRATION_START}?sub=${name.toLowerCase()}`);

          onClick();
        }}
      />
        {description && (<CardContent className={styles.nomargin}>
          <Typography className={styles.marginLeft2} variant="body1">
            {description}
          </Typography>
        </CardContent>
        )}
    </Card>
    </Box>
  );
};

BaseSubscriptionSelect.propTypes = {
  avatar: node.isRequired,
  title: string.isRequired,
  subheader: string,
  description: string,
  name: string,
  onClick: func,
  active: bool,
};

export default BaseSubscriptionSelect;
